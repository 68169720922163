import React from "react";
import Container from "../../../components/chat-channel/Container";
import Layout from "../../../components/chat-channel/Layout";
import TitleAndMetaTags from "../../../components/common/TitleAndHeader";

export default function DemoPage() {
  return (
    <>
      <TitleAndMetaTags
        title="Free Webinar for HR & IT Help Desk AI Chatbot by Workativ | Register for Workativ Assistant Webinar Here"
        description="Join our webinar on how to build Conversational AI Chatbot. Learn how to automate IT & HR support with AI Chatbot with process automation."
        keywords={["workativ terms and conditions"]}
        ogTitle="Free Webinar for HR & IT Help Desk AI Chatbot by Workativ | Register for Workativ Assistant Webinar Here"
        ogDescription="Join our webinar on how to build Conversational AI Chatbot. Learn how to automate IT & HR support with AI Chatbot with process automation."
      />
      <Container>
        <Layout logoFor="ASSISTANT">
          <DemoPageContentAndForm formName={"DemoForm"}>
            <DemoPageContentAndForm.Header>
              Combine process automation and conversational AI for Freshservice
              to create delightful employee experiences
            </DemoPageContentAndForm.Header>
            <DemoPageContentAndForm.Content>
              Why involve the helpdesk for repetitive workplace issues? Add an
              AI-powered contextual chatbot with automated workflows to your
              Freshservice to enable 24/7 conversational self-service for your
              employees and automated resolution on Slack or Microsoft Teams.
            </DemoPageContentAndForm.Content>
            <DemoPageContentAndForm.H6>
              Join to learn:
            </DemoPageContentAndForm.H6>
            <DemoPageContentAndForm.Ul>
              {liContent.map(li => (
                <DemoPageContentAndForm.Li>
                  {li.content}
                </DemoPageContentAndForm.Li>
              ))}
            </DemoPageContentAndForm.Ul>
          </DemoPageContentAndForm>
        </Layout>
      </Container>
    </>
  );
}

const liContent = [
  {
    content:
      "All the Freshservice actions you can complete with a conversational chatbot. "
  },
  {
    content:
      "Combining Freshservice + Other Business Applications to automate IT and HR processes."
  },
  {
    content: "Automations that enrich the employee experience."
  }
];

DemoPageContentAndForm.Header = ({ children }) => {
  return (
    <>
      <h1 className="webinar_header-bottom">{children}</h1>
    </>
  );
};
DemoPageContentAndForm.Content = ({ children }) => {
  return <p>{children}</p>;
};
DemoPageContentAndForm.H6 = ({ children }) => {
  return <h6>{children}</h6>;
};
DemoPageContentAndForm.Ul = ({ children }) => {
  return <ul>{children}</ul>;
};
DemoPageContentAndForm.Li = ({ children }) => {
  return <li>{children}</li>;
};

DemoPageContentAndForm.Form = ({ children }) => {
  return <>{children}</>;
};

export function DemoPageContentAndForm({ children, formName }) {
  return (
    <section className="skit_contactus sign_up-form_us demo_page webinar_forms_head">
      <div className="container signup_form">
        <div className="row beta_change_form">
          <div className="col-lg-7 col-md-6 col-sm-12 col-xs-12 ">
            <h1 className="webinar_head">Webinar</h1>
            <h1 className="webinar_header-bottom">
              Enhance your Freshservice experience with Microsoft Teams chatbot
              and workflow automation, from Workativ
            </h1>
            <p>
              According to a report by Statista, the number of daily active
              users of Microsoft Teams has more than doubled in recent months,
              increasing from 32 million users on March 2019 to 75 million on
              April 2020. For Freshservice users and organizations using
              Microsoft Teams, Workativ enables you to deliver an AI-powered
              contextual chatbot with workflow automation, to enable 24/7
              conversational self-service for your employee support via
              Microsoft Teams. 
            </p>
            <h6>Join to learn about:</h6>
            <ul>
              <li>
                Workativ’s one-click integration with Freshservice and Teams for
                AI-based Self-Service
              </li>
              <li>
                A platform purpose-built for workplace support vs. other generic
                chatbots and automation tools
              </li>
              <li>
                Auto-resolution of IT and HR issues and workflows process via MS
                Teams
              </li>
              <li>
                The value you can provide to employees with our platform,
                integrated with Freshservice and MS Teams
              </li>
            </ul>
            <p className="mb_0_webianr">
              <b>Can't make it?</b> Register anyway, and we'll send you the
              recording after the webinar.
            </p>
            <div className="webinar_footer_img">
              <h6>Presented By</h6>
              <div className="webinar_form_head_photo">
                <img
                  src={require("../../../assets/images/ic.png")}
                  alt="webinar-ic"
                />
              </div>
              <div className="webinar_form_head_photo_info">
                <div className="webinar_name">Indupriya C</div>
                <div className="webinar_title">Marketing Lead</div>
              </div>
            </div>
          </div>
          <div className="col-lg-5 col-md-6 col-sm-12 col-xs-12 demo_page_right">
            <div className="webinar_form ">
              <div className="iframe_zoho">
                <iframe
                  src="https://meetinglab.zoho.in/meeting/register?sessionId=1343398773"
                  width="100%"
                  height="100%"
                  frameBorder={0}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

// function DemoForm() {
//   return (
//     <div class="webinar_form ">
//       <div class="iframe_zoho">
//         <iframe
//           src="https://meetinglab.zoho.in/meeting/register?sessionId=1339525844"
//           width="100%"
//           height="100%"
//           frameborder="0"
//         ></iframe>
//       </div>
//     </div>
//   );
// }
